

function dec2hex(s) { return (s < 15.5 ? '0' : '') + Math.round(s).toString(16); }
    function hex2dec(s) { return parseInt(s, 16); }

    function base32tohex(base32) {
        var base32chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567";
        var bits = "";
        var hex = "";

        for (var i = 0; i < base32.length; i++) {
            var val = base32chars.indexOf(base32.charAt(i).toUpperCase());
            bits += leftpad(val.toString(2), 5, '0');
        }

        for (var i = 0; i + 4 <= bits.length; i += 4) {
            var chunk = bits.substr(i, 4);
            hex = hex + parseInt(chunk, 2).toString(16);
        }
        return hex;

    }

    function leftpad(str, len, pad) {
        if (len + 1 >= str.length) {
            str = Array(len + 1 - str.length).join(pad) + str;
        }
        return str;
    }

    function updateOtp(secretKey) {

        var key = base32tohex(secretKey);
        var epoch = Math.round(new Date().getTime() / 1000.0);
        var time = leftpad(dec2hex(Math.floor(epoch / 30)), 16, '0');

        // updated for jsSHA v2.0.0 - http://caligatio.github.io/jsSHA/
        var shaObj = new jsSHA("SHA-1", "HEX");
        shaObj.setHMACKey(key, "HEX");
        shaObj.update(time);
        var hmac = shaObj.getHMAC("HEX");
        var offset = hex2dec(hmac.substring(hmac.length - 1));
        var otp = (hex2dec(hmac.substr(offset * 2, 8)) & hex2dec('7fffffff')) + '';
        otp = (otp).substr(otp.length - 6, 6);

        $('#otp').text(otp);
    }

    function timer() {
        var epoch = Math.round(new Date().getTime() / 1000.0);
        var countDown = 30 - (epoch % 30);
        if (epoch % 30 == 0) updateOtp();
        $('#updatingIn').text(countDown);

    }

    $(function () {
        $('#otp').hide();
        $('#updating').hide();

        $('#get').on('click', function(){
            updateOtp($('#secret').val().replace(/ /g,''));

            $('#otp').show();
            $('#updating').show();
            $('#copy').show()

        })
                // navigator.clipboard.readText()
        //     .then(text => {
        // // debugger
        //         $('#secret').val(text);
        //         updateOtp(text.replace(/ /g,''));
        //     })
        //         .catch(err => {
        //         $('#secret').val("FYVIO75AF5CPLWTOODMAHA7LW77LNUB5");
        //         updateOtp("FYVIO75AF5CPLWTOODMAHA7LW77LNUB5");

        //         console.log('Something went wrong', err);
        //     })

        // $(window).on('focus',function() {
        // // debugger
        //     //do something
        //     console.log("demo")
        //     navigator.clipboard.readText()
        //     .then(text => {
        // // debugger
        //         $('#secret').val(text);
        //         updateOtp(text.replace(/ /g,''));
        //     })
        //         .catch(err => {
        //         $('#secret').val("FYVIO75AF5CPLWTOODMAHA7LW77LNUB5");
        //         updateOtp("FYVIO75AF5CPLWTOODMAHA7LW77LNUB5");

        //         console.log('Something went wrong', err);
        //     })

        // });

        // $(window).on('focus',function() {
        // // debugger
        //     //do something
        //     console.log("demo")

        // });


        $('#update').click(function (event){
            // debugger
            let secretKey = $('#secret').val().replace(/ /g,'')

            updateOtp(secretKey);
            event.preventDefault();
        });

        $('#secret').keyup(function () {
            let secretKey = $('#secret').val().replace(/ /g,'')
            updateOtp(secretKey);

            $('#otp').show();
            $('#updating').show();
            $('#copy').show()
        });

        $('#copy').click(function(){
            var otp = $('#otp').text();
            var textArea = document.createElement("textarea");
            textArea.value = otp;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("Copy");
            textArea.remove();
            $(".alert").show()
            window.setTimeout(function() {
                $(".alert").hide()
        }, 2000);
        })
        setInterval(timer, 1000);
    });

    // $(function () {
    //     navigator.clipboard.readText()
    //         .then(text => {
    //     // debugger
    //             $('#secret').val(text);
    //             updateOtp(text.replace(/ /g,''));
    //         })
    //             .catch(err => {
    //             $('#secret').val("FYVIO75AF5CPLWTOODMAHA7LW77LNUB5");
    //             updateOtp("FYVIO75AF5CPLWTOODMAHA7LW77LNUB5");

    //             console.log('Something went wrong', err);
    //         })

    //     $(window).on('focus',function() {
    //     // debugger
    //         //do something
    //         console.log("demo")
    //         navigator.clipboard.readText()
    //         .then(text => {
    //     // debugger
    //             $('#secret').val(text);
    //             updateOtp(text.replace(/ /g,''));
    //         })
    //             .catch(err => {
    //             $('#secret').val("FYVIO75AF5CPLWTOODMAHA7LW77LNUB5");
    //             updateOtp("FYVIO75AF5CPLWTOODMAHA7LW77LNUB5");

    //             console.log('Something went wrong', err);
    //         })

    //     });

    //     // $(window).on('focus',function() {
    //     // // debugger
    //     //     //do something
    //     //     console.log("demo")

    //     // });


    //     $('#update').click(function (event){
    //         // debugger
    //         let secretKey = $('#secret').val().replace(/ /g,'')

    //         updateOtp(secretKey);
    //         event.preventDefault();
    //     });

    //     $('#secret').keyup(function () {
    //         let secretKey = $('#secret').val().replace(/ /g,'')
    //         updateOtp(secretKey);
    //     });

    //     $('#copy').click(function(){
    //         var otp = $('#otp').text();
    //         var textArea = document.createElement("textarea");
    //         textArea.value = otp;
    //         document.body.appendChild(textArea);
    //         textArea.select();
    //         document.execCommand("Copy");
    //         textArea.remove();
    //         $(".alert").show()
    //         window.setTimeout(function() {
    //             $(".alert").hide()
    //     }, 2000);
    //     })
    //     setInterval(timer, 1000);
    // });